<template>
  <!--空间管理-->
  <mainBox mClass="spaceBox">
    <div class="wapBox">
      <div class="flexs left">
        <div class="l_title">
          <div class="w50">项目</div>
          <div class="w50 evts">
            <el-dropdown trigger="click">
              <span class="el-dropdown-link">
                <span class="i_add">+</span> 添加公司/项目
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>
                  <el-button class="drBtn" type="text" @click="toAddCompany"
                    >添加公司</el-button
                  >
                </el-dropdown-item>
                <el-dropdown-item>
                  <el-button
                    class="drBtn"
                    type="text"
                    :disabled="treeData.length < 1"
                    @click="toAddProject"
                    >添加项目</el-button
                  >
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
        <div class="treeWarp">
          <el-tree
            :data="treeData"
            :expand-on-click-node="false"
            :props="treeProps"
            @node-click="nodeClick"
            default-expand-all
          >
            <div
              :class="{ txBlue: actvedNode && data.projectId == initTbPid }"
              slot-scope="{ node, data }"
            >
              {{ data.projectName }}
            </div>
          </el-tree>
        </div>
      </div>
      <div class="flexs right">
        <div class="c_name">
          <span v-if="actvedNode">{{ actvedNode.companyName }}</span>
        </div>
        <div class="p_name" v-if="actvedNode">{{ actvedNode.projectName }}</div>
        <div class="p_name" v-else>-</div>
        <div class="eBtns">
          <el-button
            class="b_it"
            type="primary"
            :disabled="!actvedNode"
            icon="el-icon-plus"
            @click="toAddPosition"
            >添加位置</el-button
          >
          <el-button class="b_it" @click="importTemp">导入模版</el-button>
          <el-button class="b_it" @click="uploadModel = true">导入</el-button>
          <el-button class="b_it" @click="exportPosition">导出</el-button>
          <el-button class="b_it" @click="openPrintModal"
            >下载位置二维码</el-button
          >
          <el-button class="b_it" @click="refreshTable">刷新</el-button>
          <el-button class="b_it" @click.stop="showRowQuery">筛选</el-button>
        </div>
        <div class="tbBox">
          <div class="mmTtop">
            <div class="n_lf">位置名称</div>
            <div class="n_rt">操作</div>
          </div>
          <div class="diyCkWarp">
            <el-checkbox v-model="isTbAll" @change="checkAll"></el-checkbox>
          </div>
          <!--<TTable2 class='tbTreeCheck' ref="myTable" checkBox :checkWidth='36' :border='false' :showQuery='rowQuery'  @rowSeach='rowSeach' :tableData="[]" :isMore='false' :tableTitle="tableTitle" ></TTable2>-->
          <div class="notArrs" v-if="tableData.length < 0">暂无数据</div>
          <div class="tbTreeWarp" :class="{ tbTreeWarp2: rowQuery }">
            <el-tree
              ref="tbTree"
              class="diyTbTree"
              :default-checked-keys="dfChecks"
              v-loading="tbloading"
              :data="tableData"
              show-checkbox
              node-key="positionId"
              :props="tbProps"
              @check="checkChange"
            >
              <div class="treeNode" slot-scope="{ node, data }">
                <span class="n_name">{{ node.label }}</span>
                <span class="n_btns">
                  <el-button
                    type="text"
                    class="txBlue"
                    @click="toEditPosition(data)"
                    >编辑</el-button
                  >
                  <el-button
                    type="text"
                    class="txBlue"
                    @click="openAddPosition(data)"
                    >添加位置</el-button
                  >
                  <el-button
                    type="text"
                    class="txBlue"
                    @click="delPosition(data)"
                    >删除</el-button
                  >
                </span>
              </div>
            </el-tree>
          </div>
        </div>
      </div>
      <AddEditProject
        v-if="proInfo.open"
        :isOpen="proInfo.open"
        @close="closeAddProject"
        :dataId="proInfo.dataId"
      />
      <AddCompany
        :isOpen="compInfo.open"
        @close="closeAddCompany"
        :dataId="compInfo.dataId"
      />
      <AddPosition
        v-if="ptionInfo.open"
        :isOpen="ptionInfo.open"
        @close="closeAddPosition"
        :dataId="ptionInfo.dataId"
        :parentData="operateRow"
      />
      <TPrintCodes
        :isOpen="printInfo.open"
        @close="printInfo.open = false"
        :datas="printInfo.arrs"
      />
      <!--导入文件-->
      <el-dialog :visible.sync="uploadModel" width="40%">
        <template slot="title">
          <title-icon />{{ $t("commons.uploadFile") }}
        </template>
        <div slot="footer">
          <upload
            :ulType="uploadType"
            :excelType="excelType"
            :rootPath="rootPath"
            @success="uploadSuccess"
            @fail="uploadFail"
          ></upload>
        </div>
      </el-dialog>
    </div>
  </mainBox>
</template>
<script>
import pagination from "@/mixins/TTable/pagination";
import seachAndTable from "@/mixins/TTable/seachAndTable";
import TTable2 from "@/components/YTable/TTable2.vue";
import TPagination from "@/components/YTable/TPagination.vue";
import AddEditProject from "./model/AddEditProject.vue";
import AddCompany from "./model/AddCompany.vue";
import AddPosition from "./model/AddPosition.vue";
import TPrintCodes from "@/components/YTable/TPrintCodes.vue";
import { envInfo } from "@/constants/envInfo";
import Upload from "@/components/Upload";
import { downEmployeeTemplate } from "@/api/business/base/tenant/position";
export default {
  mixins: [pagination, seachAndTable],
  components: {
    TTable2,
    TPagination,
    AddEditProject,
    AddCompany,
    AddPosition,
    TPrintCodes,
    Upload,
  },
  data() {
    return {
      treeData: [],
      treeProps: {
        label: "projectName",
        children: "projectChildren",
      },
      tbProps: { children: "children", label: "positionName" },
      tableTitle: [
        {
          name: "位置名称",
          prop: "positionName",
          queryInfo: {
            type: "input",
            value: "",
          },
        },
        { name: "操作", width: "180", fixed: "right", slotName: "operating" },
      ],
      tableData: [],
      proInfo: {
        //新增，编辑项目的弹框信息
        open: false,
        dataId: "",
      },
      compInfo: {
        //新增，编辑公司的弹框信息
        open: false,
        dataId: "",
      },
      ptionInfo: {
        //新增，编辑位置的弹框信息
        open: false,
        dataId: "",
      },
      initTbPid: 1, //查询table数据需要的项目id
      actvedNode: null, //选中的项目节点  用于查询table数据
      operateRow: {},
      printInfo: {
        //打印
        open: false,
        arrs: [],
      },
      //导入相关 -复制之前
      uploadModel: false,
      uploadType: "ExcelImport",
      excelType: "ruge.position",
      rootPath: envInfo.bgApp.basePath,
      //导入end

      dfChecks: [],
      isTbAll: false,
      tbTreeDatas: [],
    };
  },
  created() {},
  mounted() {
    this.showRowQuery();
    setTimeout(() => {
      let arr = [
        { label: "男", value: 1 },
        { label: "女", value: 2 },
      ];
      console.log(11111);
      this.common.injectionOption2("sexStr", arr, this.tableTitle);
    }, 3000);
    this.initCompanyProjects();
  },
  computed: {},
  methods: {
    checkAll(val) {
      let Arrs = [];
      if (this.isTbAll) {
        //					this.dfChecks=[];
        this.tableData.map((item) => {
          Arrs.push(item.positionId);
        });
        this.dfChecks = Arrs;
      } else {
        this.$refs.tbTree.setCheckedKeys(Arrs, true);
      }
      //
      //				console.log('val',JSON.parse(JSON.stringify(this.tableData)),Arrs)
      //
    },
    openPrintModal() {
      //打印
      let positionList = this.$refs.tbTree.getCheckedNodes();
      if (positionList.length < 1) {
        this.$message({
          type: "info",
          message: this.$t("commons.multipleSelectionOne"),
        });
        return;
      } else {
        let Arrs = [];
        positionList.map((it) => {
          let objs = {
            name: it.descName,
            qrText: `{"type":"POSITION","positionId":` + it.positionId + `}`,
          };
          Arrs.push(objs);
        });
        this.printInfo = {
          open: true,
          arrs: Arrs,
        };
      }
    },
    async initCompanyProjects() {
      //获取公司和项目数据
      let params = {
        isloading: false,
      };
      let res = await this.ApiHttp(
        "/base/tenant/project/findCompanyProjectList",
        params,
        "GET"
      );
      console.log(res);
      if (res) {
        res.map((it, dex) => {
          it.projectName = it.companyName;
          if (dex < 1 && it.projectChildren) {
            if (it.projectChildren.length > 0) {
              this.nodeClick(it.projectChildren[0]);
              this.$nextTick(() => {
                //默认选中第一个
                setTimeout(() => {
                  let ele = $(
                    ".el-tree-node__children .el-tree-node.is-expanded:first"
                  ).addClass("dfNode1");
                }, 200);
              });
            }
          }
        });
        this.treeData = res || [];
      }
    },
    checkChange(val) {
      let Arrs = this.$refs.tbTree.getCheckedNodes();
      if (this.tbTreeDatas.length == Arrs.length) {
        this.isTbAll = true;
      } else {
        this.isTbAll = false;
      }
    },
    nodeClick(node) {
      if (node.projectId) {
        this.actvedNode = { ...node };
        this.initTbPid = node.projectId;
        this.initTable();
      } else {
        //					this.actvedNode=null;
      }
    },
    async initTable() {
      if (!this.actvedNode) return;
      this.tbloading = true;
      let params = {
        isloading: false,
        projectId: this.actvedNode.projectId,
        ...this.rowFilterData,
      };
      let res = await this.ApiHttp(
        "/base/tenant/position/findPositionTree",
        params
      );
      this.isTbAll = false;
      if (res.length > 0) {
        this.tbTreeDatas = res;
        let data = this.common.listToTree(res, "positionId", "parentId");
        this.tableData = data || [];
        //					if(!params.positionName){
        //						let diyItem={positionId: -1, positionName: this.actvedNode.projectName};
        //						res.push(diyItem)
        //						let data = this.common.listToTree(res, "positionId", "parentId");
        //						this.setNodeIsChecked(data[0].children);
        //						this.tableData = data[0].children||[];
        //						console.log('45454',data)
        //					}else{
        //						let data = this.common.listToTree(res, "positionId", "parentId");
        //						console.log('666666666',data)
        //						this.tableData = data||[];
        //					}
      } else {
        this.tableData = [];
      }
      this.$nextTick(() => {
        this.tbloading = false;
      });
    },
    setNodeIsChecked(Arrs, val = false) {
      Arrs.map((nodes) => {
        nodes.isCheck = val;
        if (nodes.children) {
          this.setNodeIsChecked(nodes.children);
        }
      });
    },
    async getPositonsInfoById(id) {
      //点击树节点 获取空间信息
      let res = await this.ApiHttp("/base/tenant/position/get/" + id);
    },

    //位置相关satrt

    openAddPosition(row, pid = "") {
      this.operateRow = {
        ...row,
        projectId: this.initTbPid,
      };
      console.log("445554", this.operateRow);
      this.ptionInfo = {
        dataId: pid,
        open: true,
      };
    },
    importTemp() {
      downEmployeeTemplate();
    },
    async exportPosition() {
      let params = {
        projectId: this.initTbPid,
      };
      let msg = await this.ApiHttp("/base/tenant/position/export", params);

      this.$message({
        type: "success",
        message: this.$t("message.operationSuccess"),
      });
      let exportObj = {
        taskId: msg,
        taskName: "Position",
        taskStatus: 0,
        rootPath: "basePath",
      };
      //将导出任务丢入导出任务列表中
      this.$store.dispatch("PushExportNotice", exportObj);
    },
    toAddPosition() {
      //点击打开弹窗
      let data = {
        positionId: "",
        positionName: "",
      };
      //				if(this.actvedNode) data.positionName=this.actvedNode.projectName;//获取上级名称
      this.openAddPosition(data);
    },
    toEditPosition(row) {
      this.openAddPosition(row, row.positionId);
    },
    closeAddPosition(isInit) {
      //关闭项目弹框
      this.ptionInfo = {
        open: false,
        dataId: "",
      };
      if (isInit) {
        this.initTable();
      }
    },

    delPosition(row) {
      this.$confirm("是否确认删除", "提示")
        .then(() => {
          let params = {
            positionId: row.positionId,
            positionName: row.positionName,
          };
          this.ApiHttp("/base/tenant/position/delete", params, "delete").then(
            (res) => {
              if (res) {
                this.initTable();
              }
            }
          );
        })
        .catch(() => {});
    },
    //位置 end

    //公司相关satrt
    toAddCompany() {
      console.log("11111", 456456);
      //点击打开弹窗
      this.compInfo = {
        open: true,
        dataId: "",
      };
    },
    closeAddCompany(isInit) {
      //关闭项目弹框
      this.compInfo = {
        open: false,
        dataId: "",
      };
      if (isInit) {
        this.initCompanyProjects();
      }
    },
    //公司相关 end

    //项目相关satrt
    toAddProject() {
      console.log("11111", 456456);
      //点击打开弹窗
      this.proInfo = {
        open: true,
        dataId: "",
      };
    },
    closeAddProject(isInit) {
      //关闭项目弹框
      this.proInfo = {
        open: false,
        dataId: "",
      };
      if (isInit) {
        this.initCompanyProjects();
      }
    },
    //项目相关 end

    // 上传文件成功
    uploadSuccess() {
      this.uploadModel = false;
      // this.$message({
      //   type: "success",
      //   message: this.$t("message.uploadSuccess"),
      // });
    },
    uploadFail() {
      this.$message({ type: "error", message: this.$t("message.uploadFial") });
    },
  },
};
</script>

<style scoped lang="scss">
.wapBox {
  height: 100%;
  display: flex;

  .flexs {
    height: 100%;
  }
  .left {
    width: 320px;
    border-right: 1px solid #eeeeee;
  }
  .l_title {
    padding: 12px 0;
    border-bottom: 1px solid #ebeef5;
    color: #2f3941;
    display: flex;
    .w50 {
      font-weight: 600;
      width: 50%;
      padding: 0 16px;
      line-height: 36px;
    }
    .evts {
      cursor: pointer;
      font-weight: 400;
      color: #4e5969;
    }
  }
  .treeWarp {
    padding: 12px 16px;
    box-sizing: border-box;
  }
  .right {
    width: calc(100% - 320px);
    padding: 16px 24px;
    .c_name {
      padding: 10px 0;
      font-size: 14px;
      color: #8993a4;
    }
    .p_name {
      color: #2f3941;
      font-size: 18px;
      font-weight: bold;
      padding-bottom: 32px;
    }
    .eBtns {
      .el-button {
        padding: 10px 18px;
      }
    }
    .tbBox {
      margin-top: 12px;
      position: relative;
      .mmTtop {
        width: 100%;
        color: #909399;
        line-height: 54px;
        padding-left: 46px;
        box-sizing: border-box;
        border-bottom: 1px solid #ebeef5;
        .n_lf {
          display: inline-block;
          width: calc(100% - 166px);
        }
        .n_rt {
          display: inline-block;
          width: 166px;
        }
      }
      .notArrs {
        text-align: center;
        color: rgb(204, 204, 204);
        padding: 12px;
      }
      .diyCkWarp {
        position: absolute;
        top: 20px;
        left: 24px;
      }
    }
  }

  .tbTreeWarp {
    margin-top: 4px;
    height: 598px;
    overflow-y: auto;
  }
  .tbTreeWarp.tbTreeWarp2 {
    height: 540px;
  }
  .diyTbTree {
    width: 100%;
    .treeNode {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 14px;
      padding-right: 8px;
    }
    .n_name {
      display: inline-block;
      width: calc(100% - 160px);
    }
    .n_btns {
      display: inline-block;
      width: 160px;
    }
    .treeNode {
    }
  }
}
</style>
<style type="text/css" lang="less">
.tbTreeCheck thead .el-checkbox {
  display: none;
}
.spaceBox .m-body {
  padding: 0 !important;
}
/*.spaceBox .el-tree-node.is-current{
		color: #1A4CEC;
	}*/
.diyTbTree {
  .el-tree-node__content {
    height: 48px;
    border-bottom: 1px solid #ebeef5;
  }
}
.treeWarp {
  .el-tree-node__content {
    height: 40px;
  }
}
.dfNode1 {
  .el-tree-node__label {
    color: #1a4cec;
  }
}
.drBtn.el-button--text {
  color: #2f3941 !important;
}
</style>