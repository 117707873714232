<template>
  <div class="Y-tips">
    <el-dialog
      class="diyModel"
      :title="dataId ? '编辑位置' : '新增位置'"
      :visible.sync="$attrs.isOpen"
      :close-on-click-modal="false"
      width="728px"
      :before-close="closeMypup"
    >
      <div>
        <TForm
          ref="htmlFrom"
          lableRow
          :autoCheck="true"
          labelPosition="left"
          :model="htmlDatas"
          :formlist="formlist"
          label-width="88px"
          :rowGutter="16"
        >
          <template slot="tagsArrs" slot-scope="scope">
            <div style="display: inline-block">
              <TagBind
                :tagValueList="htmlDatas[scope.current.keys] || []"
                tagType
                :notAuto="true"
                :limit="10"
                :maxlength="6"
                addName="添加标签"
              />
            </div>
          </template>

          <template slot="setMarker" slot-scope="scope">
            <el-input
              v-model="latObj.lng"
              style="width: calc(50% - 5px); margin-right: 10px"
              placeholder="经度"
              @change="inplatChange"
              oninput="value=value.replace(/[^\d^\.]+/g,'')"
            />
            <el-input
              v-model="latObj.lat"
              style="width: calc(50% - 5px)"
              placeholder="纬度"
              @change="inplatChange"
              oninput="value=value.replace(/[^\d^\.]+/g,'')"
            >
              <el-button
                slot="append"
                @click="openMkMap = true"
                icon="el-icon-location"
              ></el-button>
            </el-input>
          </template>
        </TForm>
        <div class="btmBtn">
          <el-button class="searchbtn" @click="closeMypup"> 取消</el-button>
          <el-button
            type="primary"
            @click="validateFroms"
            :disabled="isHttping"
          >
            保存</el-button
          >
        </div>
      </div>
    </el-dialog>
    <markerByMap
      v-if="openMkMap"
      :isOpen="openMkMap"
      @close="closeMkMap"
      @mkSave="keepMapMaker"
      :lnglats="latObj"
    ></markerByMap>
  </div>
</template>

<script>
import pinyin from "js-pinyin";
import TagBind from "@/components/TagBind";
import markerByMap from "@/views/business/base/position/tenant/markerByMap.vue";
import TForm from "@/components/YTable/TForm.vue";
import { getDetailByCode } from "@/api/ruge/ams/basicConfig/codeConfig";

export default {
  props: {
    dataId: {
      type: String | Number,
      default: "",
    },
    parentData: {
      type: Object,
      default: () => {
        return {
          positionId: "",
          positionName: "",
          projectId: "",
        };
      },
    },
  },
  components: { TForm, TagBind, markerByMap },
  data() {
    return {
      htmlDatas: {
        location: "",
      },
      latObj: { lat: "", lng: "" },
      isHttping: false,
      openMkMap: false,
      formlist: [
        {
          name: "位置名称",
          keys: "positionName",
          value: "",
          type: "input",
          isMust: true,
          inputMethod: this.changepositionName,
          maxlen: 50,
          flex: 12,
        },
        {
          name: "位置编码",
          keys: "positionCode",
          value: "",
          type: "input",
          isMust: true,
          trigger: ["blur", "change"],
          maxlen: 50,
          flex: 12,
        },
        {
          name: "上一级位置",
          keys: "parentName",
          value: this.parentData.positionName,
          type: "input",
          disabled: true,
          flex: 12,
        },
        {
          name: "备注",
          keys: "description",
          value: "",
          type: "input",
          flex: 12,
        },
        {
          name: "位置标签",
          keys: "tagList",
          value: [],
          type: "slot",
          slotName: "tagsArrs",
        },
        {
          name: "经纬度",
          keys: "location",
          value: "",
          type: "slot",
          slotName: "setMarker",
        },
      ],
    };
  },

  computed: {},
  watch: {
    dataId: {
      deep: true, // 深度监听
      handler(val, oval) {
        this.getDatasById();
        this.$nextTick(() => {
          this.$refs.htmlFrom.resetFields();
        });
      },
    },
  },
  created() {
    this.initCodeConfig();
    this.resetTFrom();
    this.getDatasById();
  },
  methods: {
    initCodeConfig() {
      // 获取位置编码maxlength
      getDetailByCode({ code: "WZBM" }).then((res) => {
        if (res && res.codeLenth) {
          this.common.insertOptions(
            this.formlist,
            "positionCode",
            res.codeLenth,
            "keys",
            "maxlen"
          );
        }
      });
    },
    closeMypup() {
      this.resetTFrom();
      this.$emit("close");
    },
    changepositionName(val) {
      // 联动企业名称  生成编码
      let n = pinyin.getCamelChars(val);
      this.htmlDatas.positionCode = n;
    },
    resetTFrom() {
      for (let item of this.formlist) {
        //初始化赋值我的表单数据
        this.$set(this.htmlDatas, item.keys, item.value);
      }
    },
    async getDatasById() {
      if (this.dataId) {
        let res = await this.ApiHttp(
          "/base/tenant/position/get/" + this.dataId
        );
        if (res) {
          res.tagList = res.tagList || [];
          if (res.latitude && res.longitude) {
            this.latObj = {
              lat: res.latitude,
              lng: res.longitude,
            };
          }
          this.htmlDatas = { ...this.htmlDatas, ...res };
        }
      } else {
        //没有ID 代表新增，重置输入框
        this.resetTFrom();
      }
    },
    validateFroms() {
      let allow = this.$refs.htmlFrom.validate((isOk) => {
        //进行校验
        if (isOk) {
          this.getFromDatas();
        } else {
          this.$message({ message: "请检查输入是否正确", type: "warning" });
        }
      });

      //
    },
    async getFromDatas() {
      this.isHttping = true;
      setTimeout(() => {
        this.isHttping = false;
      }, 2000);
      let params = {
        parentId: this.parentData.positionId,
        projectId: this.parentData.projectId,
        ...this.htmlDatas,
      };
      let urlType = "create";
      if (this.dataId) {
        urlType = "update"; //编辑
      }
      let res = await this.ApiHttp(
        "/base/tenant/position/" + urlType,
        params,
        "post"
      );
      if (res) {
        this.$refs.htmlFrom.resetFields();
        this.$emit("close", "init");
      }
    },

    closeMkMap() {
      //ych-关闭地图弹窗；
      this.openMkMap = false;
    },
    keepMapMaker(lnglat) {
      console.log("keepMapMaker", lnglat);
      this.htmlDatas.location = lnglat.lng + "," + lnglat.lat;
      this.latObj = lnglat;
      this.closeMkMap();
    },
    inplatChange() {
      this.htmlDatas.location = this.latObj.lng + "," + this.latObj.lat;
      if (!this.latObj.lng || !this.latObj.lat) {
        this.htmlDatas.location = "";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.btmBtn {
  text-align: right;
}
</style>
